import { Injectable } from '@angular/core';
import { Entity, EntityConfigInterface } from '@proman/services/entity.service';
import { OrderCopyDialogComponent } from '../components/order-copy-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { toUpper } from 'lodash';
import { ProductEditDialogComponent } from '../../products/components/product-edit-dialog.component';
import { ACL } from '@proman/services/acl.service';
import moment from 'moment';
import { DateTimeFormatService } from '@proman/services/date-time-format.service';
import { Subject } from 'rxjs';
import { mapOptionTranslate, prepareRequest, roundNumber } from '@proman/utils';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { CurrUser, TableButton, TableField } from '@proman/interfaces/object-interfaces';
import { Order, OrderProposal, OrderType, Product, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { OrderEntityInterface } from '@proman/resources/order';
import { BehaviorSubject } from 'rxjs';
import { UI_RESET_PROPOSAL, UiPreferencesService } from '@proman/services/ui-preferences.service';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';
import { DynamicFieldsService } from '@proman/services/dynamic-fields.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { EmployeesService } from '../../employees/services/employees.service';
import { setOrderTypes } from '../store';
import { ToastService } from '@proman/services/toast.service';
import { getSystemOptions } from '@proman/store/system-options';

/**
 * `Orders` service
 *
 */
@Injectable({ providedIn: 'root' })
export class OrdersService {
    orderEntity: OrderEntityInterface;
    currUser: CurrUser;
    entityConfig: EntityConfigInterface;
    permissions: Subject<any> = new Subject<any>();
    statuses: string[] = [];
    typeOptions: OrderType[];
    orderActionsTimeStamp: BehaviorSubject<number> = new BehaviorSubject(0);
    create$: Subject<void> = new Subject();
    systemOptions: SystemOptions;

  constructor(
        private Entity: Entity,
        private ACL: ACL,
        private Dialog: Dialog,
        private Filter: FilterService,
        private Toast: ToastService,
        private QueryExpression: QueryExpressionService,
        private DateTime: DateTimeFormatService,
        private PromanState: PromanStateService,
        private ParametersOptions: ParametersOptionsService,
        private store: Store,
        private DynamicFields: DynamicFieldsService,
        private Employees: EmployeesService,
        private UiPrefs: UiPreferencesService,
    ) {
        this.store.select(getCurrUser).subscribe((value) => this.currUser = value);
        this.initEntity();

        this.permissions.next({});

        for (let status in resourcesConfig.order.params.entityConfig.attrs) {
            this.statuses.push(status);
        }

        this.ParametersOptions.search({ entity: 'order_type', entityParams: {} })
            .then((response: OrderType[]) => {
                this.typeOptions = response;
                this.store.dispatch(setOrderTypes({ payload: response }))
            });

        this.store.select(getSystemOptions).subscribe((value) => this.systemOptions = value);
  }

    initEntity = () => {
        this.entityConfig = resourcesConfig.order.params.entityConfig;

        this.setStatuses(this.entityConfig);

        this.orderEntity = this.Entity.get(this.entityConfig) as unknown as OrderEntityInterface;
    };

    create = (order: OrderProposal, saleOpportunityId?: number, devProjectId?: number, internal?: boolean) => {
        const params = { proposal: order.id };
        if (internal) Object.assign(params, { internal: true });

        return this.orderEntity
            .create(params)
            .then((orderId: any) => {

                order.tags?.forEach((tag) => {
                    this.orderEntity.addAssociation({ id: orderId, tags: tag.id })
                })

                const goToCallback = () => {
                    if (this.UiPrefs.get(UI_RESET_PROPOSAL)) {
                        this.Entity.get('order_proposal').clean({ id: order.id });
                    }

                    if (internal) {
                      this.PromanState.to('OrderProductions', orderId);
                    } else {
                      this.PromanState.to('Order', orderId);
                    }

                };

                if (saleOpportunityId) {

                    this.Entity.get('loose_relation_type')
                        .get()
                        .then((defaultVal: any) => {
                            this.Entity
                                .get('loose_relation')
                                .create({
                                    fromTableName: 'sale_opportunity',
                                    fromEntityId: saleOpportunityId,
                                    toTableName: 'order',
                                    toEntityId: orderId,
                                    looseRelationType: defaultVal.id
                                })
                                .then(goToCallback);
                        });

                } else if (devProjectId) {

                    this.Entity.get('loose_relation_type')
                        .get()
                        .then((defaultVal: any) => {
                            this.Entity
                                .get('loose_relation')
                                .create({
                                    fromTableName: 'development_project',
                                    fromEntityId: devProjectId,
                                    toTableName: 'order',
                                    toEntityId: orderId,
                                    looseRelationType: defaultVal.id
                                })
                                .then(goToCallback);
                        });

                } else {
                    goToCallback();

                }

            });
    };

    refreshPrice = (order: any, callback?: () => void) => {
        this.Entity.get('order').get({ id: order.id })
            .then((response: any) => {
                order.profit = response.profit;
                order.ratioOnCreate = response.ratioOnCreate;
                order.customerPrice = response.customerPrice;
                order.customerPriceWithVat = response.customerPriceWithVat;
                order.productionPrice = response.productionPrice;
                order.commissionPercent = response.commissionPercent;
                order.commission = response.commission;
                order.version = response.version;
                order.customerDiscountPercent = response.customerDiscountPercent;
                order.agentCommissionPercent = response.agentCommissionPercent;
                order.discountSum = response.discountSum;
                if (typeof callback === 'function') callback();
            });
    };

    getLastShipmentDate = (order: any) => {
        let result: any = null;

        if (order.shipments && order.shipments.length) {
            result = order.shipments[order.shipments.length - 1].date;

        }

        return result;
    };

    getPaymentTypes = (order: Order) => {
        return [...new Set((order.accountRegisters?.map((reg) => reg.payments
            .map((pay) => pay.paymentType.name))))].join(', ');
    }

    setStatuses = (entityConfig: any) => {
        entityConfig.attrs = {};

        for (let status of resourcesConfig.order.params.orderStatuses) {
            entityConfig.attrs[toUpper(status)] = status;

        }

    };

    getOrderStatus = (order: any) => {
        let icons = resourcesConfig.order.params.orderStatusIcons;
        let icon = icons[order.status];

        return {
            icon,
            text: this.Filter.translate(order.status)
        };
    };

    getProductEditBtn = (callback: () => void, order?: Order): TableButton => {

        return {
            icon: 'edit',
            tooltip: 'edit',
            acl: null,
            dialog2: {
                component: ProductEditDialogComponent,
                locals: { order },
                options: { disableAutoFocus: true }
            },
            onSuccess: callback,
            onCancel: callback,
            show: this.canEditProducts
        };
    };

    getFields = (): TableField[] => {
        return [
            {
                name: '',
                key: 'color',
                filter: null,
                hidden: true,
                sortable: false,
                formatter: 'directive',
                formatterConfig: 'pro-entity-table-color'
            },
            {
                name: 'order_number',
                key: 'number'
            },
            {
                name: 'order_name',
                key: 'name',
                maxLength: 50
            },
            {
                name: 'customer_number',
                key: 'customerNumber',
                maxLength: 20,
            },
            {
              name: 'namespace',
              key: 'namespace',
              hidden: !this.systemOptions.corporate
            },
            {
                name: 'visitors',
                key: 'numberOfVisitors',
                hidden: true,
                stats: ['sum'],
            },
            {
                name: 'order_consumer',
                key: 'consumer.name',
                hidden: true,
            },
            {
                name: 'parameters',
                key: 'parameters',
                filter: {
                    type: 'search',
                    keys: ['parameters.parameter.name', 'parameters.parameter.alias', 'parameters.value'],
                },
                formatter: 'parameters',
                hidden: true,
                extraJoins: [
                    'parameters',
                    'parameters.parameter',
                    'parameters.children',
                    'parameters.children.parameter'
                ],
                preloadParametersData: true
            },
            {
                name: 'customer',
                key: 'customer.alias'
            },
            {
                name: 'country',
                key: 'customer.country.name',
                hidden: true,
                extraJoins: [
                    'customer.country',
                ]
            },
            {
                name: 'created_at',
                key: 'createdAt',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js',
                showTime: true
            },
            {
                name: 'finished_at',
                key: 'finishedAt',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js',
                showTime: false,
                hidden: true,
            },
            {
                name: 'confirmed_at',
                key: 'confirmedAt',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js',
                showTime: false,
                hidden: true,
            },
            {
                name: 'manager',
                key: 'manager.name'
            },
            {
                name: 'shipment',
                key: 'shipments.date',
                getValue: this.getLastShipmentDate,
                formatter: 'dateTime'
            },
            {
                name: 'quantity',
                key: 'quantity',
                filter: {
                    type: 'numeric'
                },
                formatter: 'numeric',
                stats: ['sum']
            },
            {
                name: 'weight',
                key: 'weight',
                filter: {
                    type: 'numeric'
                },
                formatter: 'numeric',
                formatterConfig: 2,
                stats: ['sum'],
            },
            {
                name: 'type',
                key: 'type',
                getValue: (row: Order) => (row.type?.name || '-'),
                filter: {
                    type: 'autocomplete',
                    entity: 'order_type'
                }
            },
            {
                name: 'subtype',
                key: 'subtype',
                getValue: (row: Order) => (row.subtype?.name || '-'),
                filter: {
                    type: 'autocomplete',
                    entity: 'order_subtype'
                },
                hidden: true,
            },
            {
                name: 'real_cost',
                key: 'productionCost',
                sortable: true,
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum'],
                acl: 'order.show_price',
                hidden: true
            },
            {
                name: 'standard_cost',
                key: 'productsCost',
                sortable: true,
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum'],
                acl: 'order.show_price',
                hidden: true
            },
            {
                name: 'sum',
                key: 'productionPrice',
                sortable: true,
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum'],
                acl: 'order.show_price',
                hidden: true
            },
            {
              name: 'customer_sum',
              key: 'customerPrice',
              sortable: true,
              formatter: 'money',
              formatterConfig: 2,
              stats: ['sum'],
              acl: 'order.show_price'
            },
            {
                name: 'final_sum',
                key: 'customerPriceWithVat',
                sortable: true,
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum'],
                acl: 'order.show_price'
            },
            {
                name: 'payment_types',
                key: 'payment_types',
                filter: {
                    type: 'search',
                    keys: ['accountRegisters.payments.paymentType.name'],
                },
                getValue: this.getPaymentTypes,
                extraJoins: [
                    'accountRegisters',
                    'accountRegisters.payments',
                    'accountRegisters.payments.paymentType',
                ],
                hidden: true,
            },
            {
                name: 'discount',
                key: 'discountSum',
                formatter: 'money',
                stats: ['sum'],
                hidden: true,
                acl: 'order.show_price'
            },
            {
                name: 'discount_percentage',
                key: 'percentage',
                getValue: this.getDiscountPercentage,
                hidden: true,
                acl: 'order.show_price'
            },

            {
                name: 'status',
                key: 'status',
                getValue: this.getOrderStatus,
                formatter: 'compile',
                filter: {
                    type: 'dropdown_multi',
                    options: this.getDropdownFilterOptions()
                }
            },
            {
                name: 'tags',
                key: 'tags',
                entityName: 'order',
                formatter: 'directive',
                formatterConfig: 'pro-tags',
                filter: {
                    type: 'autocomplete',
                    entity: 'tag'
                },
                additionalConfig: {
                  tagType: 'order',
                }
            },
            {
                name: 'desired_dispatch_date',
                key: 'desiredDispatchDate',
                formatter: 'dateTime',
                hidden: true,
            },
            {
              name: 'internal',
              key: 'internal',
              formatter: 'iconBoolean',
              hidden: true,
              filter: {
                type: 'dropdown',
                options: [
                  {
                    id: true,
                    name: this.Filter.translate('yes')
                  },
                  {
                    id: false,
                    name: this.Filter.translate('no')
                  }
                ],
                key: 'id'
              }
            },
          {
            name: 'productions_quantity',
            key: 'productionsQuantity',
            getValue: (row: Order) => {
              let quantity = 0
              row.productions?.forEach((production) => quantity += production.quantity);
              return roundNumber(quantity, 0);
            },
            filter: {
                type: 'numeric'
            },
            formatter: 'numeric',
            stats: ['sum'],
            sortable: null,
          }
        ];
    };

    getDropdownFilterOptions = () => {
        let result = [];

        if (!this.entityConfig) this.initEntity();

        for (let key in this.entityConfig.attrs) {

            if (this.entityConfig.attrs[key]) {
                result.push({
                    name: this.Filter.translate(this.entityConfig.attrs[key]),
                    id: this.entityConfig.attrs[key]
                });
            }
        }

        return result;
    };

    copyOrder = (order: Order) => {
        this.Dialog.open(OrderCopyDialogComponent, { order }, { disableAutoFocus: true })
            .then((orderId: number) => {
                this.Toast.pop('info', 'order_copy_created');
                // await this.DynamicFields.copyFields('order', order.id, orderId);
                this.PromanState.to('Order', orderId);
            });
    };

    getExtraParameters = resourcesConfig['order'].params.extraParameters;

    getCustomFilters = (keyPrefix?: any) => {
      if ( !this.checkProdSpecialisations() ) {


        if (!this.orderEntity) this.initEntity();

        let filters;

        keyPrefix = keyPrefix || '';

        filters = [
            {
                name: 'orders_preparing',
                key: keyPrefix + 'status',
                value: [
                    this.orderEntity.CONFIRMED_BY_CUSTOMER,
                    this.orderEntity.CONFIRMED_BY_MANAGER,
                    this.orderEntity.CONFIRMED_BY_TECHNOLOGIST
                ].join('|')
            },
            {
                name: 'orders_active',
                key: keyPrefix + 'status',
                value: [
                    this.orderEntity.CONFIRMED_BY_CUSTOMER,
                    this.orderEntity.CONFIRMED_BY_MANAGER,
                    this.orderEntity.CONFIRMED_BY_TECHNOLOGIST,
                    this.orderEntity.CREATED,
                    this.orderEntity.STARTED,
                    this.orderEntity.RESERVED,
                    this.orderEntity.DELIVERING
                ].join('|')
            },
            {
                name: 'orders_finished',
                key: keyPrefix + 'status',
                value: this.orderEntity.COMPLETE
            },
            {
                name: 'orders_canceled',
                key: keyPrefix + 'status',
                value: this.orderEntity.CANCELED
            },
            {
                name: 'deleted',
                key: '_deleted',
                value: true
            }
        ];

        if (this.currUser.isEmployee && this.ACL.check('order.create')) {
          filters.push(
            {
              name: 'internal',
              key: 'internal',
              value: true
            },
          )
        }

        return filters;
      };
    };

    canEditProducts = (order: any) => {
        const Order = this.orderEntity;

        if (this.currUser?.isCustomer) return (order.status === Order.CREATED || order.status === Order.CONFIRMED_BY_CUSTOMER) && this.ACL.check('product.edit');

        if (this.currUser.type === 'employee') return order.status === Order.CREATED ||
               order.status === Order.CONFIRMED_BY_MANAGER ||
               order.status === Order.CONFIRMED_BY_CUSTOMER ||
               order.status === Order.CONFIRMED_BY_TECHNOLOGIST ||
               order.status === Order.STARTED ||
               this.ACL.check('order.master');
    };

    getNew = (): any => {
        const date = this.DateTime.get('_date_js');

        return {
            name: this.Filter.translate('order') + ' ' + moment().format(date),
            products: []
        };
    };

    canComplete = (order: any) => {
        const Order = this.orderEntity;

        return new Promise((resolve) => {
            resolve(this.ACL.check('order.update_status') && order.status === Order.DELIVERING);
        });
    };

    canUnconfirm = (order: any) => {
        const Order = this.orderEntity;

        return this.ACL.check('production.create') &&
            (order.status === Order.CONFIRMED_BY_MANAGER ||
            order.status === Order.CONFIRMED_BY_TECHNOLOGIST ||
            order.status === Order.STARTED ||
            order.status === Order.RESERVED);
    };

    canSendToDevelopment = (order: any) => {
        const Order = this.orderEntity;

        return this.ACL.check('order.edit') && order.status === Order.CONFIRMED_BY_MANAGER;
    };

    canReturnFromDevelopment = (order: any) => {
        const Order = this.orderEntity;

        return this.ACL.check('order.edit') && order.status === Order.IN_DEVELOPMENT;
    };

    canCreateProductions = (order: any) => {
        const Order = this.orderEntity;

        return this.ACL.check('production.create') &&
            (
                order.status === Order.CONFIRMED_BY_MANAGER ||
                order.status === Order.STARTED ||
                order.status === Order.CONFIRMED_BY_TECHNOLOGIST ||
                order.status === Order.PREPARED ||
                order.status === Order.PARTLY_PREPARED ||
                order.status === Order.DELIVERING
            )
    };

    canConfirmProducts = (order: any): any => {
        const Order = this.orderEntity;

        return this.ACL.check('order.confirm') && (order.status === Order.CREATED || order.status === Order.CONFIRMED_BY_CUSTOMER);
    };

    canEditParameters = (order: any) => {
        const Order = this.orderEntity;

        return order.status === Order.CREATED || order.status === Order.CONFIRMED_BY_CUSTOMER || order.status === Order.CONFIRMED_BY_MANAGER
    };

    canCancel = (order: any) => {
        const Order = this.orderEntity;

        return order.status !== Order.CANCELED && order.status !== Order.COMPLETE
    };

    canStart = (order: any) => {
        const Order = this.orderEntity;

        return this.ACL.check('order.update_status') && order.status === Order.CONFIRMED_BY_MANAGER;
    };

    canRemove = () => {
        return this.ACL.check('order.remove');
    };

    canMergeOrders = () => {
        return this.ACL.check('order.edit');
    };

    canSuspend = (order: any) => {
        const Order = this.orderEntity;
        const status = order.status;
        const isCreated = status === Order.CREATED;
        const isConfirmedByManager = status === Order.CONFIRMED_BY_MANAGER;
        const isConfirmedByTechnologist = status === Order.CONFIRMED_BY_TECHNOLOGIST;
        const isStarted = status === Order.STARTED;

        return isCreated || isConfirmedByManager || isStarted || isConfirmedByTechnologist;
    };

    canResume = (order: any) => {
        const Order = this.orderEntity;

        return order.status === Order.SUSPENDED;
    };

    checkActions = (order: Order) => {
        this.permissions.next({
            canConfirmProducts: this.canConfirmProducts(order),
            canEditProductArticles: order.status === this.orderEntity.CONFIRMED_BY_MANAGER && this.ACL.check('production.create'),
            canCancel: this.canCancel(order),
            canStart: this.canStart(order),
            canRemove: this.canRemove(),
            canCreateProductions: this.canCreateProductions(order),
            canEditParameters: this.canEditParameters(order),
            canComplete: this.canComplete(order),
            canSuspend: this.canSuspend(order),
            canResume: this.canResume(order),
            canUnconfirm: this.canUnconfirm(order),
            canEditProducts: this.canEditProducts(order),
            canSendToDevelopment: this.canSendToDevelopment(order),
            canReturnFromDevelopment: this.canReturnFromDevelopment(order),
            canMergeOrders: this.canMergeOrders()
        });
    };

    isActive = (order: any) => {
        return [this.orderEntity.CANCELED, this.orderEntity.SUSPENDED, this.orderEntity.COMPLETE].indexOf(order.status) === -1;
    };

    getStatuses = () => {
        return this.statuses
            .map((item: any) => mapOptionTranslate(item, this.Filter.translate));
    };

    createOrderFromProduct = async (row: Product) => {
        const currUser = this.currUser;
        const isManager = await this.Employees.is(this.currUser.person, 'manager');

        const customerValue = await this.orderEntity
            .get({
                'products.product.id': row.id,
                'join': ['customer']
            })
            .then((response: Order) => response && response.customer);

        this.Dialog
            .open(InputDialogComponent,
                {
                    header: 'create_order',
                    mainField: { key: 'customer', name: 'customer', type: 'autocomplete', config: { entity: 'customer' }, value: customerValue },
                    parameters: [
                        {
                            key: 'manager', name: 'manager',
                            type: 'autocomplete',
                            config: { entity: 'employee', entityParams: { 'specialisations.type': 'manager' } },
                            value: isManager ? currUser.person : null,
                        },
                        {
                            key: 'orderType', name: 'order_type',
                            type: 'autocomplete',
                            config: { entity: 'order_type'},
                        },
                        {
                            name: 'quantity',
                            key: 'quantity',
                            type: 'number',
                            config: {},
                            value: row.quantity
                        }
                    ],
                    variant: 'create'
                },
                { disableAutoFocus: true })
            .then((result: { customer: number; manager: number; quantity: string }) => {
                const request = prepareRequest(Object.assign({}, result, { products: [row.id] }));

                this.orderEntity
                    .createOrder(request)
                    .then((response: {}) => this.PromanState.to('Order', response));
            });
    };

  checkProdSpecialisations() {
    if (this.currUser.person.specialisations.length > 0) {
      return (this.currUser.person.specialisations.every((spec) => spec.role.type === 'production') && !this.ACL.check('order.view_all'));
    } else {
      return false;
    }
  }

    getDiscountPercentage(row: any) {
        if (row.discountSum != 0.0) {
            return roundNumber((roundNumber(row.discountSum.amount, 2) / roundNumber(row.customerPriceWithVat.amount + row.discountSum.amount, 2)) * 100, 0);
        } else {
            return 0;
        }
    }

}
